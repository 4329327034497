<template>
  <div class="table-row">
    <p class="table-row__id">№</p>
    <div class="table-row__sort" v-if="sort">
      <ui-input placeholder="..." v-if="isEdit" v-model="addOrdValue" />
    </div>
    <div class="table-row__sort" v-if="isFinal || isCode">
      <ui-input placeholder="..." v-if="isEdit" v-model="addCode" />
    </div>
    <div class="table-row__item">
      <p v-if="!isEdit">{{ data.name }}</p>
      <ui-input placeholder="..." v-if="!isDistrictRegion && isEdit && data?.valuesClass !== 'Region'" v-model="editValue" />
      <div class="multiselect" v-if="isEdit && data?.valuesClass === 'Region' || isEdit && isDistrictRegion">
        <Multiselect
            @search-change="getCityApi"
            id="multiSelect"
            v-model="regionVal"
            :options="regionsOptions"
            placeholder=""
            :loading="isLoadingMultiSelect"
            :custom-label="getCityName"
            :close-on-select="true"
            :show-labels="false"
        >
          <template slot="noOptions" >
            {{ $t('enter-address') }}
          </template>
          <span slot="noResult">
            {{ $t('not-found') }}
          </span>
        </Multiselect>
      </div>
    </div>
    <div class="table-row__ratio" v-if="isRatio">
      <ui-input placeholder="..." v-if="isEdit" v-model="addRatio" />
    </div>
    <div class="table-row__ratio" v-if="shortName">
      <ui-input placeholder="..." v-if="isEdit" v-model="addShortName" />
    </div>
    <div class="table-row__base" v-if="isBase">
      <ui-input placeholder="..." v-if="isEdit" v-model="measureValue" />
    </div>
    <div class="table-row__periods" v-if="periods">
      <UiSelect v-model="isPublic" :options="isPublicOptions" />
    </div>
    <div v-if="isBase" class="table-row__base-select">
      <UiSelect v-model="typeField" :options="typeFieldOptions" />
    </div>
    <template v-if="isFinal">
      <div class="table-row__final-select-first table-row__final-select">
        <UiSelect v-model="federalDistrict" :options="federalDistrictOptions" />
      </div>
      <div class="table-row__final-ratio">
        <ui-input placeholder="..." v-if="isEdit" v-model="addRatio" />
      </div>
      <div class="table-row__final-select-second table-row__final-select">
        <UiSelect v-model="algorithmId" :options="getAlgorithms" />
      </div>
      <div class="table-row__final-select-third table-row__final-select">
        <UiSelect v-model="inversion" :options="inversionOptions" />
      </div>
    </template>
    <template v-if="isAlgorithm">
      <div class="table-row__algorithm">
        <ui-input placeholder="..." v-if="isEdit" v-model="algorithmNumber" />
      </div>
      <div class="table-row__algorithm-select">
        <UiSelect v-model="algorithmInversion" :options="algorithmInversionOptions" />
      </div>
    </template>
    <div v-if="showSelect" class="table-row__select">
      <UiSelect v-model="selectModel" :options="options" />
    </div>
    <div class="table-row__attention" v-if="attention">
      <ui-tooltip color="grey">
      </ui-tooltip>
    </div>
    <div class="table-row__columns" v-for="(item, key) in newColumn" v-show="columnsCount" :key="key">
      <p v-if="!isEdit">{{ item.numbers[0]?.amount }}</p>
    </div>
    <div v-if="showNewColumn" class="table-row__new-column">
      <ui-input />
    </div>
    <div class="table-row__checkbox" v-if="isCheckbox">
      <UiCheckbox v-model="isRequired" />
    </div>
    <div class="table-row__checkbox second" v-if="isCheckbox">
      <UiCheckbox v-model="showUnknownMark" />
    </div>
    <div v-if="isGlobeIcon" class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img src="@/assets/svg/admin/globe.svg" alt="">
    </div>
    <div class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img v-if="!isEdit" :src="require(`@/assets/svg/admin/edit-default.svg`)" alt="" @click="addItem">
      <img v-if="isEdit" :src="require(`@/assets/images/check-${checkColor}.png`)" alt="" @click="addItem">
    </div>
    <div v-if="!hideDeleteIcon" class="table-row__center" :style="{maxWidth: getIconsMaxWidth}">
      <img @click="showAlert = true" src="@/assets/svg/admin/table-delete-icon.svg" alt="">
    </div>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="deleteItem" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiAlert from "@/components/ui/UiAlert";
import UiButton from "@/components/ui/UiButton";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import UiCheckbox from "@/components/ui/UiCheckbox.vue";
// import algorithms from "@/views/admin/regionInformer/Algorithms.vue";

export default {
  name: "AdminTableRow",
  components: {
    UiCheckbox,
    Multiselect,
    UiTooltip,
    UiButton,
    UiAlert,
    UiInput: () => import('@/components/ui/UiInput'),
    UiSelect: () => import('@/components/ui/UiSelect')
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    attention: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    hideDeleteIcon: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: true
    },
    showNewColumn: {
      type: Boolean,
      default: false,
    },
    newColumn: {
      type: [],
      default: () => []
    },
    columnsCount: {
      type: Number,
      default: 0
    },
    isRatio: {
      type: Boolean,
      default: false
    },
    isDistrictRegion: {
      type: Boolean,
      default: false
    },
    isBase: {
      type: Boolean,
      default: false
    },
    isFinal: {
      type: Boolean,
      default: false
    },
    isAlgorithm: {
      type: Boolean,
      default: false
    },
    shortName: {
      type: Boolean,
      default: false
    },
    periods: {
      type: Boolean,
      default: false
    },
    isCheckbox: {
      type: Boolean,
      default: false
    },
    isGlobeIcon: {
      type: Boolean,
      default: false
    },
    isCode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showAlert: false,
      options: [
        { name: this.$t('integer'), value: 'Integer' },
        { name: this.$t('fractional-number'), value: 'Decimal' },
        { name: this.$t('list'), value: 'Key' },
      ],
      selectModel: { name: this.$t('integer'), value: 'Integer' },
      editValue: '',
      isEdit: false,
      addOrdValue: '',
      addRatio: '',
      isRegion: true,
      regionVal: {},
      regionsOptions: [],
      isLoadingMultiSelect: false,
      measureValue: '',
      typeField: { name: this.$t('integer'), value: 'Integer' },
      typeFieldOptions: [
        { name: this.$t('integer'), value: 'Integer' },
        { name: this.$t('fractional-number'), value: 'Decimal' },
        { name: this.$t('yes-no'), value: 'Boolean' },
      ],
      addCode: '',
      federalDistrict: {},
      federalDistrictOptions: [
        {name: this.$t('federal-district'), value: true},
        {name: this.$t('russian-federation'), value: false},
        {name: this.$t('no'), value: null}
      ],
      algorithmId: {},
      algorithmIdOptions: [
        // {name: 'Место в рейтинге, 2 параметра', id: 1},
        {name: this.$t('weighted-subtype'), id: 2},
        // {name: 'Место в рейтинге, 1 параметра', id: 3},
      ],
      inversion: {},
      inversionOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      algorithmInversion: {name: this.$t('yes'), value: true},
      algorithmInversionOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      algorithmNumber: '',
      addShortName: '',
      isPublic: {name: this.$t('yes'), value: true},
      isPublicOptions: [
        {name: this.$t('yes'), value: true},
        {name: this.$t('no'), value: false}
      ],
      isRequired: false,
      showUnknownMark: false,
    }
  },

  methods: {
    ...mapActions(['addSubtype', 'regions', 'algorithms']),
    ...mapMutations(['setDataRegions']),
    addItem() {
      if (!this.isEdit) {
        this.editValue = this.data.name;
      } else if(this.editValue || this.regionVal.name) {
        let data = {
          name: this.data?.valuesClass === 'Region' ? this.regionVal.name : this.editValue,
          id: this.data.typeId,
          dataType: this.selectModel.value,
          typeId: this.data.id,
          ord: this.addOrdValue,
          valuesClass: this.data?.valuesClass,
          ratio: +this.addRatio.toString().replace(',', '.'),
          regionVal: this.regionVal,
          baseTypeField: this.typeField.value,
          measureName: this.measureValue,
          code: this.addCode,
          algorithmId: this.algorithmId.id,
          isInversion: this.inversion.value,
          isFederalDistrictBased: this.federalDistrict.value,
          algorithmNumber: this.algorithmNumber ? +this.algorithmNumber.toString().replace(',', '.') : '',
          algorithmInversion: this.algorithmInversion.value,
          shortName: this.addShortName,
          isPublic: this.isPublic?.value,
          isRequired: this.isRequired,
          showUnknownMark: this.showUnknownMark
        }
        this.$emit('addRowField', data);
        this.isEdit = !this.isEdit;
      }
    },

    deleteItem() {
      this.$emit('deleteNewRowField')
    },

    getCityApi(query) {
      if (!query) return
      this.regionsOptions = []
      this.isLoadingMultiSelect = true
      if(this.isDistrictRegion) {
        this.regions({
          skip: 0,
          take: 200,
        }).then((res) => {
          this.regionsOptions = res.data
          this.isLoadingMultiSelect = false
        })
      } else {
        axios.get(`https://geocode-maps.yandex.ru/1.x?apikey=0449798b-7041-4730-8bbd-9cc5bbe78c42&format=json&lang=ru_RU&results=50&locality=kind&geocode=${query}`)
            .then((res) => {
              res.data.response.GeoObjectCollection.featureMember.forEach((val, idx) => {
                const { Address, kind } = val.GeoObject.metaDataProperty.GeocoderMetaData
                if(Address.country_code === 'RU' && kind === 'province') {
                  const obj = {
                    id: idx + 1,
                    name: val.GeoObject.name
                  }
                  this.regionsOptions.push(obj)
                }
              })
              this.isLoadingMultiSelect = false
            })
            .catch(() => {
              this.isLoadingMultiSelect = false
            })
      }
    },

    getCityName({ name }) {
      return name
    },
  },

  // watch: {
  //   isEdit(val) {
  //     if(val) {
  //       setTimeout(() => {
  //         document.querySelector('.table-row__item input')?.focus()
  //       })
  //     }
  //   }
  // },

  computed: {
    ...mapGetters(['getAlgorithms']),
    // algorithms() {
    //   return algorithms
    // },
    checkColor () {
      return this.editValue || this.regionVal?.name ? 'active' : 'disable'
    },

    getIconsMaxWidth () {
      if (this.isBase || this.isAlgorithm) {
        return '80px'
      } else if (this.isFinal) {
        return '50px'
      } else if (this.columnsCount > 1 || (this.showNewColumn && this.columnsCount === 1)) {
        if(this.isGlobeIcon) {
          return '5%'
        } else {
          return '7%'
        }
      } else if(!this.sort && (this.columnsCount === 0 || this.columnsCount === 1)) {
        return '105px'
      } else if (!this.sort) {
        return '162px'
      } else {
        if(this.isGlobeIcon) {
          return '85px'
        } else {
          return '130px'
        }
      }
    }
  },

  mounted () {
    this.editValue = this.data.name;
    this.isEdit = this.edit;

    if(this.isFinal) {
      this.algorithms()
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.table-row {
  display: flex;

  &__id {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 62px;
    width: 100%;
    font-size: 14px;
    font-family: 'Gotham Pro regular', sans-serif;
    font-weight: 400;
    line-height: 120.2%;
    color: #1B1A1F;
    border: 1px solid #F5F5F5;
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 62px;
    width: 100%;
    font-size: 14px;
    font-family: 'Gotham Pro regular', sans-serif;
    font-weight: 400;
    line-height: 120.2%;
    color: #1B1A1F;
    border: 1px solid #F5F5F5;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__attention {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    max-width: 130px;
    width: 100%;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 170px;

    &.second {
      max-width: 100px;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 10px 20px;
    border: 1px solid #F5F5F5;
    width: 100%;
    min-height: 40px;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__ratio {
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 10px 20px;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 256px;
    min-height: 40px;
    text-align: center;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__base {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 10px 20px;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 160px;
    min-height: 40px;
    text-align: center;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__periods {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 0 20px;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 200px;
    min-height: 40px;

    ::v-deep .ui-select__inner {
      border: none;
      padding: 0;

      &-text {
        white-space: nowrap;
      }
    }

    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
      text-align: left;
      width: 160px;
    }
  }

  &__base-select {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 0 20px;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 160px;
    min-height: 40px;

    ::v-deep .ui-select__inner {
      border: none;
      padding: 0;

      &-text {
        white-space: nowrap;
      }
    }

    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
      text-align: left;
      width: 160px;
    }
  }

  &__final-ratio {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 10px 5px;
    border: 1px solid #F5F5F5;
    width: 100%;
    max-width: 111px;
    min-height: 40px;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__final-select {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 0 10px;
    border: 1px solid #F5F5F5;
    width: 100%;
    min-height: 40px;

    ::v-deep .ui-select__inner {
      border: none;
      padding: 0;
    }

    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
      left: -30px;
      text-align: left;
      width: 160px;
    }

    &-first {
      max-width: 97px;
    }

    &-second {
      max-width: 120px;
    }

    &-third {
      max-width: 100px;
    }
  }

  &__algorithm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 180px;
    border: 1px solid #F5F5F5;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__algorithm-select {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    padding: 0 10px;
    border: 1px solid #F5F5F5;
    width: 100%;
    min-height: 40px;
    max-width: 200px;

    ::v-deep .ui-select__inner {
      border: none;
      padding: 0;
    }

    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
      left: -30px;
      text-align: left;
      width: 160px;
    }
  }

  &__new-column {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 180px;
    border: 1px solid #F5F5F5;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__columns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 180px;
    border: 1px solid #F5F5F5;

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 200px;
    border: 1px solid #F5F5F5;

    ::v-deep .ui-select__inner {
      border: none;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    max-width: 130px;
    width: 100%;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .disabled {
      cursor: not-allowed !important;
    }
  }
}
::v-deep .tooltip__window {
  max-width: 150px;
}
.title{
  color: #1B1A1F;
  font-size: 14px;
}

.text {
  color: #1B1A1F;
  font-size: 14px;
  line-height: 120.2%;
  font-family: "Gotham Pro Regular";
}
.multiselect {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
::v-deep .multiselect__tags {
  border-radius: 25px !important;
  max-height: 198px !important;
}
::v-deep .multiselect__option {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__content-wrapper {
  border-radius: 10px;
}
::v-deep .multiselect__single {
  font-size: 12px;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__option--selected.multiselect__option--highlight {
  background: none !important;
}
::v-deep .multiselect__option--selected {
  color: #E21F1F !important;
}
::v-deep .multiselect__option--highlight {
  background: none !important;
  color: #E21F1F !important;
}
::v-deep .multiselect__input {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__spinner {
  border-radius: 28px;
}

::v-deep .multiselect--above {
  .multiselect__content-wrapper {
    bottom: 100% !important;
    top: auto !important;
  }
}

::v-deep .multiselect__content-wrapper {
  top: 100% !important;
}
</style>